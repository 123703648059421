<template>
  <p class="short-info">
    {{ info }}
  </p>
</template>

<script>
import humanizeDuration from "humanize-duration";

export default {
  props: {
    contentItem: {
      type: Object,
      default: null
    },
    country: {
      type: String,
      default: ""
    },
    year: {
      type: [String, Number],
      default: ""
    },
    age: {
      type: [String, Number],
      default: ""
    }
  },
  computed: {
    infoDuration() {
      return this.contentItem
        ? this.toDuration(this.contentItem.durationSec)
        : null;
    },
    infoAge() {
      return !!this.age || this.age === 0 ? `${this.age}+` : "";
    },
    info() {
      const arr = [this.country, this.year, this.infoDuration, this.infoAge];

      return arr.filter(value => !!value).join(" • ");
    }
  },
  methods: {
    toDuration(sec) {
      return humanizeDuration(sec * 1000, {
        language: this.$i18n.locale,
        units: ["h", "m", "s"],
        round: true,
        largest: 2
      });
    }
  }
};
</script>
