<template>
  <transition name="fade">
    <main role="main">
      <meta-title :title="metaTitle"></meta-title>
      <player
        :data="contentData"
        :advertise-data="advertiseData"
        @showModal="showModalAction"
      ></player>

      <section class="section section-video-description">
        <div class="container m-container-only-s">
          <div class="row">
            <div class="col-10 col-ml-12 col-s-24">
              <h1 class="">
                {{ contentData.title }}
                <a href="" @click.prevent="toggleFavorites(contentData)">
                  <img
                    :class="{ 'opacity-40': !checkFavorites(contentData.id) }"
                    src="~@/assets/i/bookmark.svg"
                    alt=""
                  />
                </a>
              </h1>
              <movie-short-info
                :content-item="contentItem"
                :country="contentData.country"
                :year="contentData.year"
                :age="contentData.age"
              />

              <ul>
                <li>
                  <span> {{ $t("video-info.actors") }}</span>
                  {{ contentData.actors }}
                </li>
                <li
                  v-if="
                    contentData.contentItems.length &&
                      contentItem.audioTracks.length
                  "
                >
                  <span> {{ $t("video-info.audioTracks") }}</span>
                  <d
                    v-for="(item, index) in contentData.contentItems[
                      $route.params.episodes || 0
                    ].audioTracks"
                    :key="index"
                  >
                    {{ item.name }}
                    <template v-if="contentData.contentItems.length">
                      {{ contentItem.audioTracks[index + 1] ? "," : "" }}
                    </template>
                  </d>
                </li>
                <li>
                  <span> {{ $t("video-info.directors") }}</span>
                  {{ contentData.directors }}
                </li>
                <li
                  v-if="
                    contentData.contentItems.length &&
                      contentItem.subTitles.length
                  "
                >
                  <span> {{ $t("video-info.subTitles") }}</span>
                  <d
                    v-for="(item, index) in contentData.contentItems[
                      $route.params.episodes || 0
                    ].subTitles"
                    :key="index"
                  >
                    {{ item.name
                    }}{{ contentItem.subTitles[index + 1] ? "," : "" }}</d
                  >
                </li>
              </ul>
            </div>
            <div
              class="col-10 offset-2 col-sl-11 offset-sl-1 col-s-24 offset-s-0 col-text"
            >
              <p>{{ contentData.description }}</p>
            </div>
          </div>
        </div>
      </section>
      <payment-content
        :show="showModalBuyContent"
        :item="contentData"
        @closeModal="closeModal"
      ></payment-content>
      <success-payment-content
        :item="contentData"
        v-if="$route.query.payment === 'success' && !!$route.query.guid"
      ></success-payment-content>
      <error-payment-content
        :item="contentData"
        v-if="$route.query.payment === 'error' && !!$route.query.guid"
      ></error-payment-content>
    </main>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import cover from "@/mixins/cover";
import Player from "@/components/Player";
import events from "@/mixins/events";
import SuccessPaymentContent from "@/components/popup/SuccessPaymentContent";
import ErrorPaymentContent from "@/components/popup/ErrorPaymentContent";
import PaymentContent from "@/components/popup/PaymentContent";
import MovieShortInfo from "@/components/MovieShortInfo";

export default {
  name: "MovieInfo",
  title() {
    return `${this.contentData.title}`;
  },
  components: {
    PaymentContent,
    ErrorPaymentContent,
    SuccessPaymentContent,
    Player,
    MovieShortInfo
  },
  mixins: [cover, events],
  computed: {
    ...mapState("content", {
      contentData: state => state.content,
      advertiseData: state => state.advertise,
      itemLoading: state => state.itemLoading
    }),
    ...mapState("order", {
      showModalBuyContent: state => state.showModalBuyContent
    }),
    ...mapState({
      locale: state => state.locale
    }),
    ...mapGetters("favorites", {
      checkFavorites: "CHECK_EXIST"
    }),
    contentItem() {
      const episode = this.$route.params.episodes || 0;

      return this.contentData.contentItems[episode];
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setEntityContent(["content", {}]);
    this.setEntityContent(["advertise", {}]);
    next();
  },
  beforeRouteUpdate(to, from, next) {
    this.setEntityContent(["content", {}]);
    this.setEntityContent(["advertise", {}]);
    this.actionItem({ id: to.params.id }).then(() => {
      this._contentEvent(
        to.params.id,
        this.contentData.cdbId,
        this.contentData.position
      );
    });
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.setEntityContent(["content", {}]);
      vm.setEntityContent(["advertise", {}]);
      vm.actionItem({ id: to.params.id }).then(() => {
        vm._contentEvent(
          to.params.id,
          vm.contentData.cdbId,
          vm.contentData.position
        );
      });
    });
  },
  watch: {
    itemLoading: {
      handler() {
        if (this.itemLoading === "empty") {
          this.actionItem({ id: this.$route.params.id });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("content", {
      actionItem: "item"
    }),
    ...mapActions({
      setLocale: "SET_LOCALE"
    }),
    ...mapMutations("content", {
      setEntityContent: "SET_ENTITY"
    }),
    ...mapActions("favorites", {
      toggleFavorites: "toggleEntity"
    }),
    ...mapMutations("order", {
      setEntityOrder: "SET_ENTITY"
    }),
    ...mapActions("order", {
      modalBuyContent: "showModalBuyContent"
    }),
    showModalAction() {
      this.modalBuyContent();
      document.documentElement.classList.add("modal-opened");
    },
    closeModal() {
      document.documentElement.classList.remove("modal-opened");
      this.setEntityOrder(["showModalBuyContent", false]);
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
